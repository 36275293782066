import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import AdminTemplate from './AdminTemplate';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { theme } from '../themes/theme';
import AxiosContext from '../context/AxiosContext';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';




const tableCardHeadersStyles = {
  color: '#555',
  fontSize: '32px',
  fontFamily: theme.fontFamily.police.main,
};

const historiqueHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '12px',
  paddingLeft: '20px',
};

const Clients = () => {
  const axiosInstance = useContext(AxiosContext);
  const [clients, setClients] = useState([]);
  const [backUpClient, setbackUpClient] = useState([]);
  const [disabledLoginBtn, setDisabledLoginBtn] = useState(true);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { currentOrganisation } = user;
  const organisationID = currentOrganisation;

  const getAllCustomers = async () => {
    const AD_org_ID = organisationID;
    const response = await axiosInstance.get(`/customers/${AD_org_ID}`);
    console.log(response.data);
    if (response.status === 200) {
      setClients(response.data);
      setbackUpClient(response.data);
    }
  };

  const DetailClient = (client) => {
    navigate('/detailClient', { state: { ...client } });
  };

  useEffect(() => {
    (async () => {
      if (!clients.length) {
        await getAllCustomers();
        setDisabledLoginBtn(false);
      }
    })();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'N°',
        size: 150,
        Cell: ({ row }) => row.index + 1, // Incrémenter la partie numéro
      },
      {
        accessorKey: 'cBPartnerIDCustomer',
        header: 'C_BPartner_ID',
        size: 150,
      },
      {
        accessorKey: 'nom et prenom',
        header: 'Nom et Prenom',
        size: 150,
        Cell: ({ row }) => `${row.original.nom} ${row.original.prenom}`,
      },
      {
        accessorKey: 'telephone',
        header: 'Telephone',
        size: 150,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 150,
      },
      
      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 100,
        Cell: ({ row }) => (
          <Button variant="contained" onClick={() => DetailClient(row.original)}>
            <VisibilityIcon /> Détails
          </Button>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: clients,
  });

  if (disabledLoginBtn) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '250px 40px', width: '90%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography component='div' spacing={2} sx={historiqueHeader}>
                <Typography component='p' sx={tableCardHeadersStyles}>
                  Liste des clients
                </Typography>
              </Typography>
              <MaterialReactTable table={table} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminTemplate(Clients);
