import { Box, Button, Card, CardContent, Grid, Typography, CircularProgress } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import AdminTemplate from './AdminTemplate';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { theme } from '../themes/theme';
import { useNavigate } from 'react-router-dom';
import AxiosContext from '../context/AxiosContext';
import { useSelector } from 'react-redux';
import formatCurrency from '../functions/formatCurrency';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

const StyledTableHeader = styled('th')(({ theme }) => ({
  backgroundColor: '#2A4173',
  color: '#fff', 
  padding: theme.spacing(1),
  fontSize: '16px',
  textAlign:'center',
  fontFamily: theme.fontFamily.police.main,
}));


const tableCardHeadersStyles = {
    color: '#555',
    fontSize: '32px',
    fontFamily: theme.fontFamily.police.main,
    marginBottom: '32px',
};

const card = {
    position: 'relative',
    margin: '0px auto',
    padding: '.4in .3in',
    color: '#333',
    backgroundColor: '#fff',
};

const historiqueHeader = {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '20px',
    '@media print': {
        '@page': {
            size: 'A4',
            marginLeft: '-250px',
        },
        tRowsStyles: {
            display: 'none',
        },
    },
};

const buttonPrint = {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    top: '-15px',
    fontFamily: theme.fontFamily.police.main,
    '@media print': {
        display: 'none',
    },
};

const achatDevise = {
    color: '#c92a2a',
    textAlign: 'center',
    fontFamily: theme.fontFamily.police.main,
};

const venteDevise = {
    color: '#37b24d',
    textAlign: 'center',
    fontFamily: theme.fontFamily.police.main,
};

function ReturnTransaction() {
    const axiosInstance = useContext(AxiosContext);
    const { user } = useSelector((state) => state.user);
    const [allDevises, setAllDevises] = useState([]);
    const [listReturntransaction, setListReturntransaction] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            await getAllReturnTransaction();
            await getAllDevises();
            setLoading(false);
        })();
    }, []);

    const getAllReturnTransaction = async () => {
        const currentOrganisationId = user.currentOrganisation;
        try {
            const response = await axiosInstance.get(`/return-transactions/${currentOrganisationId}`);
            setListReturntransaction(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getAllDevises = async () => {
        try {
            const response = await axiosInstance.get(`/devises`);
            setAllDevises(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getCurrencyName = (currencyId) => {
        const item = allDevises.find((item) => parseInt(currencyId) === parseInt(item.C_Currency_ID));
        return item ? item['ISO_Code'] : '';
    };

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'index',
                header: 'No',
                size:100,
                Cell: ({ cell }) => cell.row.index + 1,
            },
            {
                accessorKey: 'date',
                header: 'Date',
                size:100,
            },
            {
                accessorKey: 'type',
                header: 'Operation',
                size:100,
                Cell: ({ cell }) => (
                    <span style={cell.getValue() === 'vente' ? venteDevise : achatDevise}>
                        {cell.getValue().toUpperCase()}
                    </span>
                ),
            },
            {
                accessorKey: 'exchange_rate',
                header: "Taux d'echange",
                size:100,
                Cell: ({ cell }) => formatCurrency(Number.parseFloat(cell.getValue()).toFixed(6)),
            },
            {
                accessorKey: 'quantite',
                header: 'Quantité',
                size:100,
                Cell: ({ cell, row }) => {
                    const { type, ISO_Code } = row.original;
                    return (
                        <span style={type === 'vente' ? achatDevise : venteDevise}>
                            {`${formatCurrency(parseFloat(cell.getValue()))} ${ISO_Code}`}
                        </span>
                    );
                },
            },
            {
                accessorKey: 'credit_or_debit',
                header: 'Montant',
                size:100,
                Cell: ({ cell, row }) => {
                    const { type, credit, debit, devise_id_locale } = row.original;
                    const value = type === 'vente' ? credit : debit;
                    return (
                        <span style={type === 'vente' ? venteDevise : achatDevise}>
                            {`${formatCurrency(value)} ${getCurrencyName(devise_id_locale)}`}
                        </span>
                    );
                },
            },
            {
                accessorKey: 'note',
                header: 'Note',
                size:100,
            },
        ],
        [allDevises]
    );

    const printList = () => {
        window.print();
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card sx={card}>
                        <CardContent>
                            <Typography component="div" spacing={2} sx={historiqueHeader}>
                                <Typography component="p" sx={tableCardHeadersStyles}>
                                    Liste des Transactions Retournées
                                </Typography>
                            </Typography>
                            <Typography component="h6">
                                <Button sx={buttonPrint} variant="contained" onClick={printList}>
                                    <LocalPrintshopOutlinedIcon /> Imprimer
                                </Button>
                            </Typography>
                            <MaterialReactTable
                                columns={columns}
                                data={listReturntransaction}
                                initialState={{ pagination: { pageIndex: 0, pageSize: 10 } }}
                                enableRowSelection={false}
                                enablePagination
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AdminTemplate(ReturnTransaction);






