import { Box, Card,
    CardContent,
   CircularProgress, Grid,Typography } from '@mui/material'

import React, {useEffect, useState, useContext} from 'react'
import  {tCellStyles} from '../components/TableComponent';
import AdminTemplate from './AdminTemplate'
import { theme } from '../themes/theme';
import AxiosContext from '../context/AxiosContext'
import { useSelector } from 'react-redux';
import Carousel from 'better-react-carousel'
import formatCurrency from '../functions/formatCurrency';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';


const cardTitle = {
color: '#adb5bd',
fontSize: '18px',
fontFamily: theme.fontFamily.police.main,
borderBottom: '1px solid #adb5bd',
marginBottom: '32px',
}

const achatDevise = {
display:'flex',
color:'#c92a2a',
textAlign:'center',
fontFamily: theme.fontFamily.police.main,
}

const venteDevise = {
display:'flex',
color:'#37b24d',
textAlign:'center',
fontFamily: theme.fontFamily.police.main,
}

const SoldCardStyled    = { bgcolor: '#5F70A7', minHeight: '120px', color: '#fff'}

const SoldCardHeaderStyled  = {
fontSize: {md: '18px'}, 
fontWeight: '800',
textTransform: {md: 'uppercase'}, 
marginBottom: '10px'
}

const SoldCardContentStyled = { 
display: 'flex', 
justifyContent: 'space-between' 
}


const ListSoldCarousel = ({allSoldes, getCurrencieISOCode}) => {
return (
 <Carousel cols={2} rows={1} gap={10} loop>
   {allSoldes.map((solde, key)=>(
       <Carousel.Item key={key}>
           <SoldCard amount={formatCurrency(solde[0].solde)} 
                   devise={getCurrencieISOCode(solde[0].C_Currency_ID)} /> 
       </Carousel.Item>
   ))}
 </Carousel>
)
}


const SoldCard = ({amount, devise, style})=>{

return (<>
           <Card xs={12} sm={4} sx={{...SoldCardStyled, 
                                   '& .MuiCardContent-root': {padding: '4px'},
                                   display: { xs: 'none', sm: 'block' }}} >
               <CardContent>
                   <Typography component='p' sx={SoldCardHeaderStyled}>
                       Solde
                   </Typography>
                   <Typography component='div' sx={SoldCardContentStyled}>
                       <Typography component='div' sx={{ fontSize: '2.2rem' }}>
                           {`${formatCurrency(amount)} ${devise}`}
                       </Typography>
                   </Typography>                                
               </CardContent>
           </Card>
           <Card xs={12} sm={4} sx={{...SoldCardStyled, 
                                   '& .MuiCardContent-root': {padding: '4px'},
                                   display: { xs: 'block', sm: 'none' }}} >
               <CardContent>
                   <Typography component='p' sx={SoldCardHeaderStyled}>
                       Solde
                   </Typography>
                   <Typography component='p'>
                       {`${formatCurrency(amount)} ${devise}`}
                   </Typography>                                
               </CardContent>
           </Card>
       </>);
}


function Home() {
const axiosInstance = useContext(AxiosContext);
const [listTransaction, setListTransaction] = useState([]);
const {currentOrganisation, user: {Countries: organisationCountries}} = useSelector((state)=>state.user.user);
const [organisationCurrencies, setOrganisationCurrencies] = useState([]);
const [allSoldes, setAllSoldes] = useState([]);
const [disabledLoginBtn, setDisabledLoginBtn] = useState(true);


useEffect(()=>{
   (async()=>{
     if(!listTransaction.length){
       await getOrganisationCurrencies();
       await getAllMouvements();
       await getAllSoldes();
       setDisabledLoginBtn(false);
     }
   })();
}, []);

const getOrganisationCurrencies = async()=>{
   const currencies = await axiosInstance.get(`/devises`);
   if(currencies.status === 200){
       setOrganisationCurrencies(currencies.data);
   }
}

const getCurrencieISOCode = (currencieID)=>{
   
   const currencie = organisationCurrencies.find((item)=>parseInt(item.C_Currency_ID) === parseInt(currencieID));
   return currencie?.ISO_Code;
}
const getCurrencyName = (currencyId)=> {       
   const item = organisationCurrencies.find((item)=> (parseInt(currencyId) === parseInt(item.C_Currency_ID)));
   return (item) ? item['ISO_Code'] : '';
 }

const getAllMouvements = async()=>{
   const response = await axiosInstance.get(`/transactions/${currentOrganisation}`);
   if(response.status === 200){
       const data = response.data;
       console.log(data);
       const lastTransaction = data?.slice(0,5);
       setListTransaction(lastTransaction);
   }
}

const getAllSoldes = async()=>{
   
   const response = await axiosInstance.get(`/stock-solde/${currentOrganisation}`);
   const data = response.data;
   
   if(response.status === 200){
       setAllSoldes(data);
   }
}



const columns = [
   {
       header: 'No',
       accessorKey: 'no',
       size:90,
       Cell: ({ cell }) => <Typography sx={tCellStyles}>{cell.row.index + 1}</Typography>
   },
   {
       header: 'Date',
       accessorKey: 'date',
       size:90,
       Cell: ({ cell }) => <Typography sx={tCellStyles}>{cell.getValue()}</Typography>
   },
   {
       header: 'Réference',
       accessorKey: 'reference',
       size:90,
       Cell: ({ cell }) => <Typography sx={tCellStyles}>{cell.getValue()}</Typography>
   },
   {
       header: 'Opération',
       accessorKey: 'type',
       size:90,
       Cell: ({ cell }) => (
           <Typography sx={cell.getValue() === 'vente' ? venteDevise : achatDevise}>
               {cell.getValue().toUpperCase()}
           </Typography>
       )
   },
   {
       header: 'Devise',
       accessorKey: 'ISO_Code',
       size:150,
       Cell: ({ cell }) => <Typography sx={tCellStyles}>{cell.getValue()}</Typography>
   },
   {
       header: 'Taux d\'echange',
       accessorKey: 'exchange_rate',
       size:150,
       Cell: ({ cell }) => <Typography sx={tCellStyles}>{formatCurrency(cell.getValue())}</Typography>
   },
   {
       header: 'Quantité',
       accessorKey: 'quantite',
       size:150,
       Cell: ({ cell }) => {
           const transaction = cell.row.original;
           return (
               <Typography sx={transaction.type === 'vente' ? achatDevise : venteDevise}>
                   {`${formatCurrency(parseFloat(transaction.quantite))} ${transaction.ISO_Code}`}
               </Typography>
           );
       }
   },
   {
       header: 'Montant',
       size:100,
       accessorKey: 'montant_total',
       Cell: ({ cell }) => {
           const transaction = cell.row.original;
           return (
               <Typography sx={transaction.type === 'vente' ? venteDevise : achatDevise}>
                   {`${formatCurrency(transaction.vente.montant_total)} ${getCurrencyName(transaction.devise_id_locale)}`}
               </Typography>
           );
       }
   }
];


const table = useMaterialReactTable({
   columns,
   data: listTransaction,
 });

 if (disabledLoginBtn) {
   return (
       <Box sx={{ display: "flex", justifyContent: "center", alignItem:"center" ,heigth:"100%",padding:"250px 40px",width:"90%", }}>
           <CircularProgress />
       </Box>
   );
}

return (
<Box>
   <Grid container spacing={2} sx={{ marginBottom: '12px' }}>
       <Grid item xs={12}>
           <Typography component='p' sx={cardTitle}>
               Stock de devises
           </Typography> 
           <Grid  container spacing={2}> 
               {(allSoldes.length >= 1) ? (
                   <Grid item xs={12} sm={12} md={12}>
                       <ListSoldCarousel allSoldes={allSoldes} getCurrencieISOCode={getCurrencieISOCode}/>
                   </Grid>
               ):<Grid item xs={4}> Pas de devises configuré </Grid>}
           </Grid>
       </Grid>
   </Grid>
   <Grid container spacing={1}>
       <Grid item xs={12} sx={{display: {xs: 'none', sm: 'block'}}}>
           <Card>
               <CardContent>
                   <Typography component='p' sx={cardTitle}>
                       Vos dernières opérations
                   </Typography> 
                   <MaterialReactTable table={table} />
               </CardContent>
           </Card>
       </Grid>
   </Grid>
</Box>
)
}

export default AdminTemplate(Home)