import React from 'react'
import { useSelector } from 'react-redux';
import permissions from '../data/permissions';

function AuthGuard({action, children}) {
  const {user: {RolesOfUser}} = useSelector((state)=>state.user.user);

  const hasPermission = () => {
    if (permissions[action]) {
      if (typeof permissions[action] === 'string') {
        return RolesOfUser?.some(item => item.AD_Role_ID === permissions[action]);
      }
      else if (Array.isArray(permissions[action])) {
        return permissions[action].some(roleId => RolesOfUser?.some(item => item.AD_Role_ID === roleId));
      }
    }
    return false;
  };

  if (hasPermission()) {
    return (
      <>
        {children}
      </>
    );
  } else {
    return null;
  }
}

export default AuthGuard
