import { Stack } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import user, { logout } from '../state/user/user';
import { useDispatch, useSelector } from 'react-redux';
import { AlertMessage } from '../components/AlertMessage';
import AxiosContext from '../context/AxiosContext';
import { useMemo } from 'react';
import ResponsiveDrawer from '../components/DrawerBase';



const AdminTemplate = WrappedComponent =>{

  function Admin() {

    const {currentOrganisationName, currentOrganisation, user: {Countries: organisationCountries, userRoleName}} 
                        = useSelector((state)=>state.user.user);
    const axiosInstance = useContext(AxiosContext);
    const [alertMessage, setAlertMessage] = useState({status: false, message: '', type: 'error'});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const doLogout = ()=>{
      dispatch(logout());
    }
    
    const setAlert= (type, message)=>{
        setAlertMessage({status: true, message, type});
    }

    const getOrganisationCurrencies = async()=>{

      let mainCountryIdOfOrganization;

      if(organisationCountries[0].length > 1){
        mainCountryIdOfOrganization = organisationCountries[0][0].C_Country_ID;
      }else{
        mainCountryIdOfOrganization = organisationCountries[0].C_Country_ID;
      }
      
      const {data} = await axiosInstance.get(`/devises/${currentOrganisation}/${mainCountryIdOfOrganization}`);
      if(data['organisationDevises'] && data['organisationDevises'].length <= 0){
          setAlert('warning', 'Configurer au moins une devise pour cette organisation.')
          navigate('/config');
      }
    }
    
    useMemo(getOrganisationCurrencies, [axiosInstance, currentOrganisation, navigate, organisationCountries]);
  
    return (    
      <ResponsiveDrawer doLogout={doLogout} OrgName={currentOrganisationName}>
        <Stack spacing={2} sx={{ maxWidth: 600 }}>
          <AlertMessage params={alertMessage} />
        </Stack>
        <WrappedComponent  setAlert={setAlert}/>
      </ResponsiveDrawer>
    )
  }

  return Admin;
}
export default AdminTemplate;