import React, { useState, useEffect, useContext } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import AxiosContext from '../context/AxiosContext';
import { useLocation } from 'react-router-dom';
import { theme } from '../themes/theme';
import formatCurrency from '../functions/formatCurrency';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import AdminTemplate from './AdminTemplate';

const historiqueHeader = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
    paddingLeft: '20px',
};

const tableCardHeadersStyles = {
    color: '#555',
    fontSize: '42px',
    fontFamily: theme.fontFamily.police.main,
    marginBottom: '32px',
};

const achatDevise = {
    color: '#c92a2a',
    textAlign: 'center',
    fontFamily: theme.fontFamily.police.main,
};



const venteDevise = {
    color: '#37b24d',
    textAlign: 'center',
    fontFamily: theme.fontFamily.police.main,
};

const columns = [
    {
        accessorKey: 'index',
        header: 'No',
        size:100,
        Cell: ({ cell }) => cell.row.index + 1,
    },
    { 
        accessorKey: 'date',
        header: 'Date',
    },
    {
        accessorKey: 'type',
        header: 'Operation',
        size:150,
        Cell: ({ cell }) => (
            <span style={cell.getValue() === 'vente' ? achatDevise :  venteDevise}>
                {cell.getValue().toUpperCase()}
            </span>
        ),
    },
    {
        accessorKey: 'quantity',
        header: 'Montant',
        Cell: ({ cell, row }) => {
            const quantity = cell.getValue();
            const { type } = row.original;
            return (
                <span style={type === 'vente' ? achatDevise : venteDevise}>
                    {`${formatCurrency(quantity)} ${row.original.currencyName}`}
                </span>
            );
        },
    },
    {
        accessorKey: 'solde',
        header: 'Solde',
        size:150,
        Cell: ({ cell, row }) => {
            const solde = cell.getValue();
            const { type } = row.original;
            return (
                <span style={type === 'vente' ? achatDevise : venteDevise}>
                    {`${formatCurrency(solde)} ${row.original.currencyName}`}
                </span>
            );
        },
    },
    { accessorKey: 'commentaire', header: 'Commentaire' },
];

function StockRapports() {
    const axiosInstance = useContext(AxiosContext);
    const { state } = useLocation();
    const [items, setItems] = useState(state.data);
    const [disabledLoginBtn, setDisabledLoginBtn] = useState(true);
    const [allDevises, setAllDevises] = useState([]);
    const [currentCurrency, setCurrentCurrency] = useState(null);
    const { currentOrganisation, user: { Countries: organisationCountries } } = useSelector((state) => state.user.user);
    const [organisationCurrencies, setOrganisationCurrencies] = useState([]);

    useEffect(() => {
        (async () => {
            await getOrganisationCurrencies();
            await getAllDevises();
            setDisabledLoginBtn(false);
        })();
    }, []);

    const getCurrencyName = (C_Currency_ID) => {
        const item = allDevises.find((item) => (parseInt(C_Currency_ID) === parseInt(item.C_Currency_ID)));
        return (item) ? item['ISO_Code'] : '';
    }

    const getAllDevises = async () => {
        const currencies = await axiosInstance.get(`/devises`);
        if (currencies.status === 200) {
            setAllDevises(currencies.data);
        }
    }

    const getOrganisationCurrencies = async () => {
        const mainCountryIdOfOrganization = organisationCountries[0].C_Country_ID;
        const { status, data } = await axiosInstance.get(`/devises/${currentOrganisation}/${mainCountryIdOfOrganization}`);
        if (status === 200) {
            setOrganisationCurrencies(data.organisationDevises);
            const currency = Number.parseInt(data.mainCurrency[0].C_Currency_ID);
            setCurrentCurrency(currency);
        }
    }

    const formattedItems = items.map(item => ({
        ...item,
        currencyName: getCurrencyName(item.C_Currency_ID),
        currentCurrency,
    }));

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card sx={{ marginBottom: '12px' }}>
                        <CardContent>
                            <Typography component='div' spacing={2} sx={historiqueHeader}>
                                <Typography component='p' sx={tableCardHeadersStyles}>
                                    Rapport de Stocks 
                                </Typography>
                            </Typography>
                            <MaterialReactTable
                                columns={columns}
                                data={formattedItems}
                                initialState={{ pagination: { pageIndex: 0, pageSize: 10 } }}
                                enableRowSelection={false}
                                enablePagination
                            />          
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AdminTemplate(StockRapports);
