import { Box, Button, Card, CardContent, Grid, TableCell, TableRow, Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import AdminTemplate from './AdminTemplate';
import AxiosContext from '../context/AxiosContext';
import { useSelector } from 'react-redux';
import { theme } from '../themes/theme';
import TableComponent from '../components/TableComponent';
import {  listFormConfigHeader } from '../data/historiqueData';
import { AddOutlined, EditNoteOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';

  
  const tableCardHeadersStyles = {
    color: '#555',
    fontSize: '32px',
    fontFamily : theme.fontFamily.police.main,
    marginBottom: '32px',
  }
  
  const card = {
    position: "relative",
    margin:  "0px auto",
    padding:  ".4in .3in",
    color:  "#333",
    backgroundColor:  "#fff",
  }
  
  const tRowsStyles = {
    '&:nth-child(even)':{
        bgcolor: '#f1f3f5'
    }
  }

  
  const button = {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    fontFamily: theme.fontFamily.police.main,
  }

  const btnIcon = {
    display:'flex',
    justifyContent:'space-evenly',
    marginRight:'10px',
    color: '#ffff',
    textDecoration: 'none'
  }

  const actions = {
    display:'flex',
    justifyContent:'space-evenly',
    alignItems:'center',
    fontFamily: theme.fontFamily.police.main,
  }
  
  const tableContent = {
    textAlign:'center',
    fontFamily: theme.fontFamily.police.main,
  } 

function ListConfigForm() {

    const axiosInstance = useContext(AxiosContext);
    const {user} = useSelector((state)=>state.user);
    const countries = user.user.Countries;
    console.log(countries[0].C_Country_ID);
    console.log(countries[0].CountryName);
    const [listeFormConfig, setListeFormConfig] = useState([]);
    const [activeCountries, setActiveCountries] = useState([]);
    const {currentOrganisation} = user;
    const C_Country_ID          =  user.user.Countries[0].C_Country_ID; 


    useEffect(()=>{
        (async()=>{
            await getListForm();
            await getActiveCountries();
        })();      
    }, []);

    const getActiveCountries = async()=>{
        const response = await axiosInstance.get('/active-countries');
        if(response.status == 200){
          setActiveCountries(response.data);
        }
    }

    const getListForm = async()=>{
        const response = await axiosInstance.get(`/get-liste-config-form`);
        setListeFormConfig(response.data);
    }

    const getCountryName = (countryId)=>{
        const country = activeCountries.find((item)=> item.C_Country_ID == parseInt(countryId));
        return (country) ?  country['Name'] : '';
    }
    
    return (
        <>
            <Box>
                <Grid container>
                    <Typography component='div' sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button startIcon={<AddOutlined />}
                                variant='contained'
                                size='large'
                                sx={{ backgroundColor: 'primary', fontSize: 14, marginBottom: 2, alignSelf: 'flex-end' }}> 
                            <Link style={{color: '#ffff', textDecoration: 'none'}} to={'/add-config-form'}>Nouveau formulaire</Link>
                        </Button>
                    </Typography> 
                </Grid>
                <Grid container>
                    <Grid item xs={6} md={12} lg={12} sm={12}>
                        <Card sx={card}>
                            <CardContent>
                            <Typography component='p' sx={tableCardHeadersStyles}>
                                  Liste des formulaires configurés
                            </Typography> 
                            <TableComponent columns={listFormConfigHeader} >
                                {(listeFormConfig.length) > 0 ? listeFormConfig.map((item, key)=>(
                                  ( item.C_Country_ID == C_Country_ID)) ? (
                                    <TableRow sx={tRowsStyles} key={key}>
                                      <TableCell sx={tableContent}>{ key + 1 }</TableCell>
                                      <TableCell sx={tableContent }>{ getCountryName(item.C_Country_ID) }</TableCell>
                                      <TableCell sx={actions}>
                                          <Button sx={button} variant="contained" >
                                              <Link style={{color: '#ffff', textDecoration: 'none'}} to={`/edit-config-form/${item.C_Country_ID}`}>
                                                <EditNoteOutlined sx={btnIcon}/> Editer
                                              </Link>
                                          </Button> 
                                      </TableCell>
                                    </TableRow> 
                                  ) :null                       
                                  ) : 
                                    <TableRow>
                                          <TableCell colSpan={8} sx={{fontSize: '22px', textAlign: 'center'}}>La Liste des configurations de formulaire est vide.</TableCell>
                                    </TableRow> 
                              }     
                            </TableComponent>
                            </CardContent>
                        </Card>
                    </Grid> 
                </Grid>
            </Box>
        </>
    )
}
export default AdminTemplate(ListConfigForm);
