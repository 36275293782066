import { Box, Button, Card,
    CardContent,
    CircularProgress,Grid,Input,InputAdornment,MenuItem,TextField,Typography } from '@mui/material';
import React, { useContext, useEffect, useState} from 'react';
import AdminTemplate from './AdminTemplate'
import { useSelector } from 'react-redux';
import AxiosContext from '../context/AxiosContext';
import { useNavigate } from 'react-router-dom';
import OperationDeviseForm from '../components/OperationDeviseForm';
import OperationClientForm from '../components/OperationClientForm';
import { getCountryPhoneCode, getCurrentUserCountryCode } from '../utils';
import { PhoneAndroidOutlined } from '@mui/icons-material';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/input';
import { parsePhoneNumberFromString, getCountryCallingCode } from 'libphonenumber-js';
import 'react-phone-number-input/style.css';



const Operation = ({setAlert}) => {

    const navigate      = useNavigate();
    const {user}        = useSelector((state)=>state.user);
    const AD_User_ID    = user.user.AD_User_ID;
    const adresseAgence = user.user.address;
    const numeroAgence  = user.user.phone;
    const axiosInstance = useContext(AxiosContext);
    const {currentOrganisation} = user;
    const C_Country_ID   =  user.user.Countries[0].C_Country_ID; 
    const organisationID = currentOrganisation;
    const NumberIDEmployer = user.user.C_BPartner_ID;
    const [disabledLoginBtn, setDisabledLoginBtn] = useState(false);
    const [montantTotal, setMontantTotal]  = useState(0);
    const [quantite, setQuantite]  = useState(0);
    const [tauxChange, setTauxChange]  = useState(0);
    const [nom, setNom]  = useState('');
    const [prenom, setPrenom]  = useState('');

    const [cBPartnerIDCustomer,setCBPartnerIDCustomer] = useState('');
    const [IdCard, setIdCard] = useState('');
    const [email, setEmail]  = useState('');
    const [isCustomerExiste, setIsCustomerExiste]  = useState(false);
    const [operation, setOperation]  = useState({});
    const [handleDeviseLocale, setHandleDeviseLocale]  = useState({});
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [activeCountries, setActiveCountries]   = useState([]);
    const [telephone, setTelephone] = useState('');
    const [countrieCode, setCountrieCode] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [countryparamsId, setCountryparamsId] = useState('');

  
    
    useEffect(()=>{
        (async()=>{
          await getActiveCountries();
            const currentUserCountryCode = await getCurrentUserCountryCode();
            setCountrieCode(currentUserCountryCode);
        })();
        
    },[]);

    
    const handleOperationInputsChange = (event)=>{
        event.preventDefault();
        if(event.target.type == 'file'){
            setOperation({...operation, [event.target.name] : event.target.files[0]}); 
        }else{
            setOperation({...operation, [event.target.name] : event.target.value}); 
        } 
    }

    const handleFileIdentity = (event) => {
        event.preventDefault();
        if(event.target.type === 'file'){
            setIdCard({...IdCard, [event.target.name] : event.target.files[0]});
        }
        console.log(IdCard);
    }

    const handleOperationInputChange = (event)=>{
        event.preventDefault();
        setOperation({...operation, [event.target.name] : event.target.value});        
    }

    const getActiveCountries = async()=>{
        const { status, data} = await axiosInstance.get(`/active-countries`);
        console.log(data);
    
        if(status === 200){
          setActiveCountries(data);
        }
    } 

    const saveOperation = async(event)=>{
        
        event.preventDefault();
        
        const formData = new FormData();

        if (!isValid) {
            setAlert('Numéro de téléphone invalide');
            return;
        }

        if(!nom){
            setAlert('error', 'Le nom est obligatoire.');
            return;
        }

        if(!prenom){
            setAlert('error', 'Le prenom est obligatoire.');
            return;
        }

        if(!email){
            setAlert('error', 'L\'email est obligatoire.');
            return;
        }

        const countriePhoneCode = await getCountryPhoneCode(countrieCode);
        const telephoneWithCountrieCode = `${countriePhoneCode}${telephone}`;
        

        console.log(telephoneWithCountrieCode,telephoneWithCountrieCode);

        if(!telephone ){
            setAlert('error', 'Le telephone est obligatoire.');
            return;
        }

        if(!operation.operation){
            setAlert('error', 'Selectionner le type de l\'operation.');
            return;
        }

        if(!operation.cashout_currency){
            setAlert('error', 'Selectionner la devise l\'operation.');
            return;
        }

        if(!operation.montant_source || operation.montant_source == 0){
            setAlert('error', 'Le montant de l\'operation est obligatoire et superieure à zero.');
            return;
        }

        if (telephoneWithCountrieCode.length < 13) {
            setAlert('error', "Le numéro de téléphone n'est pas correct.");
            return;
        }
        
        formData.append('operation',operation.operation);
        formData.append('reference_no', Math.floor(Math.random() * 100000000));
        formData.append('C_Country_ID',C_Country_ID);
        formData.append('nom', nom);
        formData.append('prenom', prenom);
        formData.append('email', email);
        formData.append('telephone', telephoneWithCountrieCode );
        formData.append('adresseAgence', adresseAgence);
        formData.append('numeroAgence', numeroAgence);
        formData.append('isCustomerExiste', isCustomerExiste);
        formData.append('countryparamsId', countryparamsId);
        formData.append('cBPartnerIDCustomer', cBPartnerIDCustomer);
        formData.append('AD_User_ID', AD_User_ID);
        formData.append('AD_Org_ID', organisationID);
        formData.append('exchange_rate', parseFloat(tauxChange));
        formData.append('inverse_exchange_rate', 1/parseFloat(tauxChange));
        formData.append('quantite', parseFloat(quantite));
        formData.append('montant_total', parseFloat(montantTotal));
        formData.append('IdCard',IdCard.idCard);
        formData.append('cashout_currency', operation.cashout_currency);
        formData.append('devise_id_locale', parseInt(handleDeviseLocale));
        formData.append('date', currentDateTime.toLocaleString('en-US', { year: 'numeric', month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        }));
        formData.append('employerConnecte', `${user.user.first_name} ${user.user.last_name}`);
        formData.append('numeroIDEmployer',NumberIDEmployer);

        let dynamicInfo = operation;

        delete dynamicInfo.cashout_currency;
        delete dynamicInfo.montant_source;
        delete dynamicInfo.operation;
        
        formData.append('dynamic_Info', JSON.stringify(dynamicInfo));
        
        Object.keys(operation).forEach((key)=> {
            formData.append(key, operation[key]);
        });

        const response = await axiosInstance.post(`/vente`, formData);

        setDisabledLoginBtn(true);

        if(response.status === 200) {
            setDisabledLoginBtn(false);
            let data = response.data;
            setOperation({});
            setAlert('success', 'Operation enregistrée avec succès.');
            setTimeout(()=>window.location.reload(), 2000);
            
            navigate('/FactureVente/data',{ state:{...data} });
        }else{
            setDisabledLoginBtn(false);
            setAlert('error', 'Une erreur est survenue veuillez bien verifier les informations que vous envoyer dans les formulaires.');
        }
    }

    const handleMontantTotalChange = (montant)=>{
        setMontantTotal(montant)
    }

    const handleQuantiteChange = (quantite)=>{
        setQuantite(quantite)
    }

    const handleChangeTelephone = async (event) => {
        
        const phone = event.target.value;

        setTelephone(phone);
    
        if (countrieCode) {
            const phoneNumber = parsePhoneNumberFromString(phone, countrieCode);
            setIsValid(phoneNumber ? phoneNumber.isValid() : false);
    
            if (phoneNumber && phoneNumber.isValid()) {

                const AD_Org_ID = organisationID;
                const countriePhoneCode = await getCountryPhoneCode(countrieCode);
                const telephoneWithCountrieCode = `${countriePhoneCode}${phone}`;

                console.log(telephoneWithCountrieCode);
                try {
                    const {status, data} = await axiosInstance.get(`/check-customer/${AD_Org_ID}/${telephoneWithCountrieCode}`);
                
                    setDisabledLoginBtn(true);

                    if (status === 200 && Object.keys(data).length != 0) {

                    console.log(data);
                    
                    setDisabledLoginBtn(false);

                    const nom    = data.LastName;
                    const prenom = data.FirstName;
                    const email  = data.Email;
                    const C_BPartner_ID = data.C_BPartner_ID;

                    setNom(nom?.toUpperCase() || '' || data.nom?.toUpperCase() || '');
                    setPrenom(prenom?.toUpperCase() || '' || data.prenom?.toUpperCase() || '');
                    setEmail(email?.toUpperCase() || '' || data.email?.toUpperCase() || '');
                    setCBPartnerIDCustomer(C_BPartner_ID || data.C_BPartner_ID);
                    setIsCustomerExiste(true);

                    const countryCode = countrieCode;
                    const countries   = activeCountries;
                    for (var key in countries) {
                        if (countries.hasOwnProperty(key)) {
                            var country = countries[key];
                            if (country.CountryCode == countryCode) {
                                setCountryparamsId(country.C_Country_ID);
                                console.log(country.C_Country_ID);
                                break;
                            }
                        }
                    }
                    } else {

                        setDisabledLoginBtn(false);
                        setNom('');
                        setPrenom('');
                        setEmail('');
                        setIsCustomerExiste(false);
                        setCBPartnerIDCustomer('');

                        const countryCode = countrieCode;
                        const countries = activeCountries;
                        for (const key in countries) {
                            if (countries.hasOwnProperty(key)) {
                                const country = countries[key];
                                if (country.CountryCode == countryCode) {
                                    setCountryparamsId(country.C_Country_ID);
                                    break;
                                }
                            }
                        }
    
                    }
                } catch (error) {
                    console.error(error);
                }
            }else{
                setDisabledLoginBtn(false);
                setNom('');
                setPrenom('');
                setEmail('');
                setIsCustomerExiste(false);
                setCBPartnerIDCustomer('');

                const countryCode = countrieCode;
                const countries = activeCountries;
                for (const key in countries) {
                    if (countries.hasOwnProperty(key)) {
                        const country = countries[key];
                        if (country.CountryCode == countryCode) {
                            setCountryparamsId(country.C_Country_ID);
                            break;
                        }
                    }
                }
            }
        }
    };

    const handleChangeCountryCode = (event) => {
        setCountrieCode(event.target.value);
    
        if (telephone) {
          const phoneNumber = parsePhoneNumberFromString(telephone, event.target.value);
          setIsValid(phoneNumber ? phoneNumber.isValid() : false);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        switch (name.toUpperCase()) {
          case 'COUNTRYCODE':
            setCountrieCode(value);
            validatePhoneNumber(telephone, value);
            break;
          default:
            return false;
        }
    };


    const validatePhoneNumber = (telephone, countryCode) => {
        const parsedNumber = parsePhoneNumberFromString(telephone, countryCode);
        if (parsedNumber) {
          setIsValid(parsedNumber.isValid());
        } else {
          setIsValid(false);
        }
    };

    const handleKeyUpNom = (event) => {
        setNom(event.target.value.toUpperCase());
    };

    const handleKeyUpPrenom = (event) => {
        setPrenom(event.target.value.toUpperCase());
    };

    const handleKeyUpEmail = (event) => {
        setEmail(event.target.value.toUpperCase());
    };

    if (disabledLoginBtn) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItem:"center" ,heigth:"100%",padding:"250px 40px",width:"90%", }}>
                      <CircularProgress />
            </Box>
        );
    }

    return (
        <Card sx={{ minWidth: 275 ,mb: 2 }}>
            <CardContent>
                <form onSubmit={saveOperation}>
                    <Typography sx={{ fontSize:'32px'}}>
                        Operation
                    </Typography>
                    <Typography component='div'>
                        <OperationDeviseForm 
                                    axiosInstance={axiosInstance}
                                    currentOrganisation={currentOrganisation}
                                    C_Country_ID={C_Country_ID}
                                    handleMontantTotalChange={handleMontantTotalChange}
                                    handleOperationInputChange={handleOperationInputChange}
                                    handleQuantiteChange={handleQuantiteChange}
                                    setTauxChange={setTauxChange}
                                    setHandleDeviseLocale={setHandleDeviseLocale} />
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Input  
                                    type="hidden"
                                    fullWidth={false}
                                    name='C_BPartner_ID'
                                    onChange={(event)=>setCBPartnerIDCustomer(event.target.value)}
                                    />
                                    <Input
                                        type="hidden"
                                        fullWidth={false}
                                        name="setCountryparamsId"
                                        onChange={(event) => setCountryparamsId(event.target.value)}
                                    />
                                    <Input  
                                    type="hidden"
                                    fullWidth={false}
                                    name='isCustomerExiste'
                                    onChange={(event)=>setIsCustomerExiste(event.target.value)}
                                    />
                                <Grid item xs={12}>

                                <TextField
                                    label="Votre numéro de téléphone"
                                    className="input"
                                    variant="outlined"
                                    name="telephone"
                                    value={telephone}
                                    onChange={handleChangeTelephone}
                                    fullWidth
                                    InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                        <PhoneAndroidOutlined />
                                        </InputAdornment>
                                    ),
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <TextField
                                            select
                                            value={countrieCode}
                                            onChange={handleChangeCountryCode}
                                            name="countryCode"
                                            variant="outlined"
                                        >
                                            {activeCountries.map((option) => (
                                            <MenuItem key={option.CountryCode} value={option.CountryCode}>
                                                <img
                                                loading="lazy"
                                                width="20"
                                                src={`https://flagcdn.com/w20/${option.CountryCode.toLowerCase()}.png`}
                                                srcSet={`https://flagcdn.com/w40/${option.CountryCode.toLowerCase()}.png 2x`}
                                                alt=""
                                                />
                                            </MenuItem>
                                            ))}
                                        </TextField>
                                        </InputAdornment>
                                    ),
                                    }}
                                    required
                                    error={!isValid}
                                    helperText={!isValid && "Numéro de téléphone invalide"}
                                />

                                </Grid>   
                                    
                                </Grid>  
                                
                                <Grid container spacing={2}>
                                <Grid item xs={4}>
                                        
                                        <TextField
                                            id="nom"
                                            label="Nom"
                                            variant="outlined"
                                            type="text"
                                            placeholder="Entrer votre nom"
                                            fullWidth={true}
                                            margin="normal"
                                            name='nom'
                                            required
                                            value={nom}
                                            onKeyUp={handleKeyUpNom}
                                            onChange={(event) => setNom(event.target.value)}
                                        />                     
                                </Grid>  
                                <Grid item xs={4}>
                                    <TextField
                                        id="prenom"
                                        label="Prénom"
                                        variant="outlined"
                                        type="text"
                                        placeholder="Entrer votre prénom"
                                        fullWidth={true}
                                        margin="normal"
                                        name='prenom'
                                        required
                                        onKeyUp={handleKeyUpPrenom}
                                        value={prenom}
                                        onChange={(event)=>setPrenom(event.target.value)}
                                    />                             
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="email"
                                        label="Email"
                                        variant="outlined"
                                        type="text"
                                        placeholder="Entrer votre email"
                                        fullWidth={true}
                                        margin="normal"
                                        name='email'
                                        required
                                        onKeyUp={handleKeyUpEmail}
                                        value={email}
                                        onChange={(event)=>setEmail(event.target.value)}
                                    />                             
                                </Grid> 
                                </Grid>   
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="idCard"
                                            label="pieced'identite"
                                            variant="outlined"
                                            type="file"
                                            placeholder="Entrer votre pièce d'identité"
                                            fullWidth={true}
                                            margin="normal"
                                            name='idCard'
                                            value={operation.idCard}
                                            onChange={handleFileIdentity} 
                                        />                     
                                    </Grid>  
                                    
                                </Grid>     
                            </Grid>
                        </Grid>
                        <OperationClientForm 
                                handleOperationInputsChange={handleOperationInputsChange} 
                                montantTotal = {montantTotal}
                                quantite={quantite} />
                        <Button style={{ marginTop: '10px' }}
                                variant="contained" 
                                disabled={(disabledLoginBtn || !isValid ) ? true : false}  
                                type='submit'>
                                    {disabledLoginBtn && <CircularProgress style={{position: 'absolute'}} />}
                                Valider 
                        </Button>  
                    </Typography>   
                </form>   
            </CardContent>
           
        </Card>
        
    );

};




export default AdminTemplate(Operation)