import { Box, Button,  Typography } from '@mui/material';
import React, {useEffect, useState, useContext} from 'react'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { useSelector } from 'react-redux';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import formatCurrency from '../functions/formatCurrency';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import AxiosContext from '../context/AxiosContext';
import { styled } from '@mui/material/styles';

function ImprimerFacture() {
  
  const axiosInstance = useContext(AxiosContext);
  const {state}  = useLocation();
  const {user}   = useSelector((state)=>state.user);
  const organisationName = user.currentOrganisationName;
  const navigate = useNavigate();  
  const [allDevises, setAllDevises] = useState([]);
  const [informationclt, setInformationclt] = useState([]);


  useEffect(()=>{
    (async()=>{
        await getAllDevises();
        await getAllUsers();
    })();
  }, []);

  const impression = () => {
    window.print();
  }
  const retour   = () => {
    navigate('/historique');
  }

  const getAllDevises = async()=>{        
    const currencies = await axiosInstance.get(`/devises`);
    if(currencies.status === 200){
        setAllDevises(currencies.data);
    }
  }
  const getAllUsers = async () => {
    const users = await axiosInstance.get(`/AllCustomers`);
    if(users.status === 200){
      setInformationclt(users.data);
    }
  } 


  const getCurrencyName = (currencyId)=> {       
    const item = allDevises.find((item)=> (parseInt(currencyId) === parseInt(item.C_Currency_ID)));
    return (item) ? item['ISO_Code'] : '';
  }


const ReceiptContainer = styled(Box)(({ theme }) => ({
  width: '80mm',
  minHeight: '200mm',
  margin: '0 auto',
  padding: '10mm',
  backgroundColor: '#fff',
  boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  fontFamily: 'Arial, sans-serif',
  color: '#333',

  
 '@media print': {
    width: '80mm',
    height: '200mm',
    margin: 0,
    // padding: '2mm',
    boxShadow: 'none',
    pageBreakAfter: 'always',
    '@page': {
      size: '80mm 180mm',
      margin: 0,
    },
  }
}));

const Header = styled(Typography)(({ theme }) => ({
  fontSize: '14pt',
  fontWeight: 'bold',
  textAlign: 'center',
  color: theme.palette.primary.main,
  marginBottom: '5mm',
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  fontSize: '10pt',
  textAlign: 'center',
  marginBottom: '3mm',
}));


const Section = styled(Box)(({ theme }) => ({
  marginBottom: '5mm',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '12pt',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  marginBottom: '2mm',
}));

const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: '10pt',
  marginBottom: '1mm',
}));

const AmountText = styled(Typography)(({ theme }) => ({
  fontSize: '12pt',
  fontWeight: 'bold',
  textAlign: 'right',
  color: theme.palette.success.main,
}));

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: '8pt',
  textAlign: 'center',
  marginTop: '5mm',
  color: theme.palette.text.secondary,
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10mm',
  '@media print': {
    display: 'none',
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
}));

  return (
    <ReceiptContainer>
      <Header>Bureau de change : {organisationName}</Header>
      <SubHeader>
        Adresse : {state.vente?.adresseAgence ? state.vente.adresseAgence.replace(/ï¿½/g, "e").toUpperCase() : ''}
      </SubHeader>
      <SubHeader>Tel: {state.vente?.numeroAgence}</SubHeader>
      <SubHeader>Caissier: {state.vente?.numeroIDEmployer}</SubHeader>
      
      <Divider />
      
      <Section>
        <SectionTitle>Information du client</SectionTitle>
        <InfoText>Nom: {state.vente?.nom} {state.vente?.prenom}</InfoText>
        <InfoText>Telephone: {state.vente?.telephone}</InfoText>
      </Section>
      
      <Divider />
      
      <Section>
        <SectionTitle>Détails de l'opération</SectionTitle>
        <InfoText>Type: {state?.type.toUpperCase() || state.vente.operation.toUpperCase()}</InfoText>
        <InfoText>Date: {state.date}</InfoText>
        <InfoText>N°ref: {state.reference}</InfoText>
        <InfoText>Taux: {Number.parseFloat(state.vente.exchange_rate).toFixed(3)}</InfoText>
        <InfoText>Taux Inverse: {Number.parseFloat(state.vente.inverse_exchange_rate).toFixed(8)}</InfoText>
        <AmountText>
          Montant: {formatCurrency(state.quantite, state.ISO_Code)}
        </AmountText>
      </Section>
      
      <Divider />
      
      <Section>
        <SectionTitle>
          {state.type === "achat" || state.vente.operation === "achat" ? "Le client a reçu :" : "Le client a payé :"}
        </SectionTitle>
        <AmountText>
          {formatCurrency(state.vente.montant_total)} {getCurrencyName(state.vente.devise_id_locale)}
        </AmountText>
      </Section>
      
      <FooterText>
        Veuillez vérifier votre reçu et votre devise avant de quitter le comptoir. Merci.
      </FooterText>
      <FooterText>
        CauriFx est une plateforme de mycauri.com / Tous droits réservés © GUB Finances S.A.
      </FooterText>
      
      <ButtonContainer>
        <StyledButton variant="contained" color="primary" onClick={impression} startIcon={<LocalPrintshopOutlinedIcon />}>
          Imprimer
        </StyledButton>
        <StyledButton variant="contained" color="secondary" onClick={retour} startIcon={<KeyboardDoubleArrowLeftOutlinedIcon />}>
          Retour
        </StyledButton>
      </ButtonContainer>
    </ReceiptContainer>
  );
}

export default (ImprimerFacture);