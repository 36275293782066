import { Box, Button,  Divider,  Typography } from '@mui/material';
import React, {useEffect, useState, useContext} from 'react'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import formatCurrency from '../functions/formatCurrency';
import AxiosContext from '../context/AxiosContext';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

function FactureVente() {
    const axiosInstance = useContext(AxiosContext);
    const navigate = useNavigate();
    const {state}  = useLocation();
    const [organisationCurrencies, setOrganisationCurrencies] = useState([]);
    const [informationclt, setInformationclt] = useState([]);
    const {user} = useSelector((state)=>state.user);
    const organisationName = user.currentOrganisationName;

    const impression = () => {
        window.print();
    }
    const retour   = () => {
        navigate('/operation');
    }

    useEffect(()=>{
      
        (async()=>{
            await getOrganisationCurrenciesDevise();
            await getAllUsers();
            await getCurrencieISOCodeLocale();
        })();
      },[]);

      
    const getOrganisationCurrenciesDevise = async()=>{
        const currencies = await axiosInstance.get(`/devises`);
        if(currencies.status === 200){
            setOrganisationCurrencies(currencies.data);
        }

    }

    const getAllUsers = async () => {
      const users = await axiosInstance.get(`/AllCustomers`);
      if(users.status === 200){
        setInformationclt(users.data);
      }
    } 

    let C_Currency_ID_Locale = state.devise_id_locale;

    const getCurrencieISOCodeLocale = (C_Currency_ID_Locale)=>{  
      const currencieLocal = [...organisationCurrencies].find((item)=> item.C_Currency_ID.toString() === C_Currency_ID_Locale);
      return currencieLocal?.ISO_Code;
    }
    let C_Currency_ID    = state.cashout_currency;

    const getCurrencieISOCode = (C_Currency_ID) => {
        const currencie = [...organisationCurrencies].find((item)=> item.C_Currency_ID.toString() === C_Currency_ID
        );
        return currencie?.ISO_Code;
    }

const ReceiptContainer = styled(Box)(({ theme }) => ({
  width: '80mm',
  minHeight: '200mm',
  margin: '0 auto',
  padding: '10mm',
  backgroundColor: '#fff',
  boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  fontFamily: 'Arial, sans-serif',
  color: '#333',

  
 '@media print': {
    width: '80mm',
    height: '200mm',
    margin: 0,
    // padding: '2mm',
    boxShadow: 'none',
    pageBreakAfter: 'always',
    '@page': {
      size: '80mm 180mm',
      margin: 0,
    },
  }
}));

const Header = styled(Typography)(({ theme }) => ({
  fontSize: '14pt',
  fontWeight: 'bold',
  textAlign: 'center',
  color: theme.palette.primary.main,
  marginBottom: '5mm',
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  fontSize: '10pt',
  textAlign: 'center',
  marginBottom: '3mm',
}));


const Section = styled(Box)(({ theme }) => ({
  marginBottom: '5mm',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '12pt',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  marginBottom: '2mm',
}));

const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: '10pt',
  marginBottom: '1mm',
}));

const AmountText = styled(Typography)(({ theme }) => ({
  fontSize: '12pt',
  fontWeight: 'bold',
  textAlign: 'right',
  color: theme.palette.success.main,
}));

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: '8pt',
  textAlign: 'center',
  marginTop: '5mm',
  color: theme.palette.text.secondary,
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10mm',
  '@media print': {
    display: 'none',
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
}));

  return (
    <ReceiptContainer>
      <Header>Bureau de change : {organisationName}</Header>
      <SubHeader> Adresse : {state.adresseAgence.replace(/ï¿½/g, "e").toUpperCase()}</SubHeader>
      <SubHeader>Tel: {state?.numeroAgence}</SubHeader>
      <SubHeader>Caissier: {state?.numeroIDEmployer}</SubHeader>
      
      <Divider />
      
      <Section>
        <SectionTitle>Information du client</SectionTitle>
        <InfoText>Nom: {state?.nom} {state?.prenom}</InfoText>
        <InfoText>Telephone: {state?.telephone}</InfoText>
      </Section>
      
      <Divider />
      
      <Section>
        <SectionTitle>Détails de l'opération</SectionTitle>
        <InfoText>Type: {state.operation.toUpperCase() }</InfoText>
        <InfoText>Date: {state.date}</InfoText>
        <InfoText>N°ref: {state.reference_no}</InfoText>
        <InfoText>Taux: {Number.parseFloat(state.exchange_rate).toFixed(3)}</InfoText>
        <InfoText>Taux Inverse: {Number.parseFloat(state.inverse_exchange_rate).toFixed(8)}</InfoText>
        <AmountText>
          Montant: {`${ formatCurrency(Number.parseFloat(state.quantite).toFixed(3))} ${getCurrencieISOCode(C_Currency_ID)}`}
        </AmountText>
      </Section>
      
      <Divider />
      
      <Section>
        <SectionTitle>
          {state.type === "achat" || state.operation === "achat" ? "Le client a reçu :" : "Le client a payé :"}
        </SectionTitle>
        <AmountText>
          {`${ formatCurrency(Number.parseFloat(state.montant_total).toFixed(3))} ${getCurrencieISOCodeLocale(C_Currency_ID_Locale)}`}
        </AmountText>
      </Section>
      
      <FooterText>
        Veuillez vérifier votre reçu et votre devise avant de quitter le comptoir. Merci.
      </FooterText>
      <FooterText>
        CauriFx est une plateforme de mycauri.com / Tous droits réservés © GUB Finances S.A.
      </FooterText>
      
      <ButtonContainer>
        <StyledButton variant="contained" color="primary" onClick={impression} startIcon={<LocalPrintshopOutlinedIcon />}>
          Imprimer
        </StyledButton>
        <StyledButton variant="contained" color="secondary" onClick={retour} startIcon={<KeyboardDoubleArrowLeftOutlinedIcon />}>
          Retour
        </StyledButton>
      </ButtonContainer>
    </ReceiptContainer>
  );
}
export default (FactureVente);