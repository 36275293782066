import { Box, Button, Card, CardContent, Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, 
    Modal, TableCell, TableRow, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react';
import AdminTemplate from './AdminTemplate';
import TableComponent, {tRowStyles, tCellStyle, MobileTableComponent} from '../components/TableComponent'
import { AddOutlined, NavigateBeforeOutlined, NavigateNextOutlined } from '@mui/icons-material'
import AxiosContext from '../context/AxiosContext'
import { useSelector } from 'react-redux'
import dynamicSort from '../functions/sort';
import formatCurrency from '../functions/formatCurrency';
const currenciesColumns = ['No', 'Monaie', 'Nous achetons à', 
                            'Nous vendons à', 'M. minimale', 'M. maximale'];

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const listDeviseContainer = {
    height: 250,
    border: 'solid 1px #ced4da',
    overflowY: 'scroll'
}

const modalHeader = {
    borderBottom: 'solid 1px #ced4da'
}

function Config({setAlert}) {

    const axiosInstance = useContext(AxiosContext);
    const [listOfCurrencies, setListOfCurrencies] = useState();
    const [listOfCurrenciesToDisplay, setListOfCurrenciesToDisplay] = useState([]);
    const [selectCurrenciesToAdd, setSelectCurrenciesToAdd]         = useState([]);
    const [selectCurrenciesToRemove, setSelectCurrenciesToRemove]   = useState([]);
    const [alreadySelectedCurrencies, setAlreadySelectedCurrencies] = useState([]);
    const [organisationCurrencies, setOrganisationCurrencies] = useState([]);
    const [mainCurrency, setMainCurrency] = useState(null);
    const [organisationRates, setOrganisationRates] = useState([]);
    const {currentOrganisation, user: {Countries: organisationCountries, C_BPartner_ID}} 
                        = useSelector((state)=>state.user.user);

    const {user} = useSelector((state)=>state.user);
    console.log(user);

    const [openAddCurrencieModal, setOpenAddCurrencieModal] = useState(false);
    
    const handleOpenAddCurrencieModal  = () => setOpenAddCurrencieModal(true);
    const handleCloseAddCurrencieModal = () => setOpenAddCurrencieModal(false);
    const [disabledLoginBtn, setDisabledLoginBtn] = useState(true);
    const [searchInListOfCurrenciesToDisplay, setSearchInListOfCurrenciesToDisplay] = useState(null);
    const [searchInAllReadySelectedCurrencie, setSearchInAllReadySelectedCurrencie] = useState(null);
    const [disabledAddDeviseBtn, setDisabledAddDeviseBtn] = useState(false);

    useEffect(()=>{        
        (async()=>{
            await getOrganisationRates();
            setDisabledLoginBtn(false);
        })();
    }, []);

    const getOrganisationRates = async(event, page)=>{
        const mainCountryIdOfOrganization = organisationCountries[0].C_Country_ID;
        const {status, data} = await axiosInstance.get(`/org-rates/${mainCountryIdOfOrganization}/${currentOrganisation}`);
        
        if(status === 200){
            setOrganisationRates(data);
        }
    }

    const getOrganisationCurrencies = async()=>{
        const mainCountryIdOfOrganization = organisationCountries[0].C_Country_ID;
        const {status, data} = await axiosInstance.get(`/devises/${currentOrganisation}/${mainCountryIdOfOrganization}`);
        if(status === 200){
            setOrganisationCurrencies(data.organisationDevises);
            setMainCurrency(data.mainCurrency[0].C_Currency_ID);
        }
    }

    const getListOfCurrencies = async()=>{
        const currencies = await axiosInstance.get(`/devises`);
        if(currencies.status === 200){
            const orgCurrenciesIds = organisationCurrencies?.map(({C_Currency_ID})=>C_Currency_ID);
            const currenciesContent = currencies.data.filter((item)=>!orgCurrenciesIds.includes(item.C_Currency_ID));
            
            currenciesContent.sort(dynamicSort('ISO_Code'));
            setListOfCurrencies(currenciesContent);
            setListOfCurrenciesToDisplay(currenciesContent)
        }
    }

    useMemo(getOrganisationCurrencies, [axiosInstance, currentOrganisation, organisationCountries]);
    useMemo(getListOfCurrencies, [axiosInstance, organisationCurrencies]);

    const selectCurrencieForAdd = (event)=>{
        const indexOfThisElement = selectCurrenciesToAdd.indexOf(`${event.target.value}`);
        if(indexOfThisElement <= -1){
            setSelectCurrenciesToAdd([...selectCurrenciesToAdd,  event.target.value]);
        }else{
            setSelectCurrenciesToAdd(selectCurrenciesToAdd.filter((item, key)=> key !== indexOfThisElement));
        }
    }

    const selectCurrenciesForRemove = (event)=>{
        const indexOfThisElement    = selectCurrenciesToRemove.indexOf(`${event.target.value}`);
        if(indexOfThisElement <= -1){
            setSelectCurrenciesToRemove([...selectCurrenciesToRemove,  event.target.value]);
        }else{
            setSelectCurrenciesToRemove(selectCurrenciesToRemove.filter((item, key)=> key !== indexOfThisElement));
            
        }
    }

    const moveToSelectedCurrencies = ()=>{
        setSelectCurrenciesToRemove([]);
        const getCurrencies = listOfCurrencies.filter((item, key)=>
            selectCurrenciesToAdd.includes(item.C_Currency_ID.toString()));
        const newListOfCurrenciesToDisplay = listOfCurrenciesToDisplay.filter((item)=>
            !selectCurrenciesToAdd.includes(item.C_Currency_ID.toString()))
        setAlreadySelectedCurrencies(getCurrencies);  
        setListOfCurrenciesToDisplay(newListOfCurrenciesToDisplay);    
        
    }

    const removeSelectedCurrencies = () => {

        setSelectCurrenciesToAdd([]);
        const getCurrencies = alreadySelectedCurrencies.filter((item, key)=>
            !selectCurrenciesToRemove.includes(item.C_Currency_ID.toString()));
        const removedCurrencies = alreadySelectedCurrencies.filter((item, key)=>
            selectCurrenciesToRemove.includes(item.C_Currency_ID.toString()));
        setAlreadySelectedCurrencies(getCurrencies);  
        setListOfCurrenciesToDisplay([...removedCurrencies, ...listOfCurrenciesToDisplay]);    
    }

    const saveSelectedCurrencies = async()=>{
        const postData = [];
        
        if(!alreadySelectedCurrencies) return;
        setDisabledAddDeviseBtn(true);

        alreadySelectedCurrencies.forEach((item, key)=>{
            postData.push({ C_Currency_ID: item.C_Currency_ID, 
                            ISO_Code: item.ISO_Code, 
                            AD_Org_ID: currentOrganisation,
                            user: user.user});
        });
        
        const response = await axiosInstance.post(`/devises/add-to-organization/${currentOrganisation}`, postData);
        console.log(response);
        if(response.status === 200){
           setOpenAddCurrencieModal(false);
           setAlreadySelectedCurrencies([]);
           setSelectCurrenciesToAdd([]);
           await getOrganisationCurrencies();
           await getOrganisationRates();
           setAlert('success', 'Devise Ajouter avec succès');
        }
        
        setDisabledAddDeviseBtn(false);
    }

    const getListOfCurrenciesToDisplay = ()=>{
        if(!searchInListOfCurrenciesToDisplay){
            return listOfCurrenciesToDisplay;
        }
        return listOfCurrenciesToDisplay.filter((item)=>item.ISO_Code.toString().toLowerCase()
                                                    .includes(searchInListOfCurrenciesToDisplay.toString().toLowerCase()));
    }

    const getAlreadySelectedCurrencies = ()=>{
        if(!searchInAllReadySelectedCurrencie){
            return [...organisationCurrencies, ...alreadySelectedCurrencies];
        }
        return [...organisationCurrencies,...alreadySelectedCurrencies].filter((item)=>item.ISO_Code.toString().toLowerCase()
                                                    .includes(searchInAllReadySelectedCurrencie.toString().toLowerCase()));
    }

    const showListOfCurrenciesToDisplay = useMemo(getListOfCurrenciesToDisplay, 
        [searchInListOfCurrenciesToDisplay, listOfCurrenciesToDisplay]);

    const showAlreadySelectedCurrencies = useMemo(getAlreadySelectedCurrencies, 
        [searchInAllReadySelectedCurrencie, organisationCurrencies, alreadySelectedCurrencies]);


    const getCurrencyName = (currencyId)=>{       
        const item = organisationCurrencies.find((item)=> (parseInt(currencyId) === parseInt(item.C_Currency_ID)));
        return (item) ? item['ISO_Code'] : '';
    }

    const customizeRateAttributes = async(event)=>{
        const name = event.target.getAttribute('name');
        const value = event.target.innerText.replace(/\s/g, '').trim();
        const rateId = event.target.getAttribute('rateid');

        if(!value || !value.length){
            setAlert('error', 'La cellule doit avoir obligatoirement une valeur.');
            return;
        }
        if(Number.isNaN(Number(value))){
            setAlert('error', 'Vous devez saisir une valeur valide');
            return;
        }
       
        const mainCountryIdOfOrganization = organisationCountries[0].C_Country_ID;
        const response = await axiosInstance.get(`/rates/update/${mainCountryIdOfOrganization}/${currentOrganisation}/${rateId}/${name}/${value}`);
     
        if(response.status === 200){
            setAlert('success', response.data);            
        }

        if(response.status === 401){
            setAlert('error', response.data);            
        }
    }

  return (
    <>
        <Box>
            <Grid container>
                <Typography component='div' sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={handleOpenAddCurrencieModal}
                            startIcon={<AddOutlined />}
                            variant='contained'
                            size='large'
                            sx={{ backgroundColor: 'primary', fontSize: 14, marginBottom: 2, alignSelf: 'flex-end' }}> 
                        Ajouter une devise 
                    </Button>
                </Typography> 
                <Modal
                        open={openAddCurrencieModal}
                        onClose={handleCloseAddCurrencieModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        
                    >
                    <Box sx={{...style, width: {xs: '95%', sm: '500px'}, minHeight: {sm: '300px'}}}>
                        <Typography style={modalHeader} component="h4">
                            Ajouter de nouvelles devises
                        </Typography>
                        <Typography component='div' sx={{ mt: 2, display: {xs: 'block', sm: 'block'}}}>
                            <Grid container style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Grid item xs={4}>
                                    <Typography component='div' style={listDeviseContainer}>
                                        <TextField
                                                label="Rechercher"
                                                type="search"
                                                variant="filled"
                                                onChange={(event)=> setSearchInListOfCurrenciesToDisplay(event.target.value)}
                                            />

                                        {showListOfCurrenciesToDisplay.length ? showListOfCurrenciesToDisplay.map((currencie, key)=>(
                                            <MenuItem key={key}>
                                                <FormControlLabel 
                                                    sx={{
                                                        '& .MuiFormControlLabel-label':{
                                                            fontSize: {xs: '12px', sm: '18px'}                                                       
                                                        }
                                                    }}
                                                    control={
                                                        <Checkbox 
                                                            checked={selectCurrenciesToAdd.indexOf(`${currencie.C_Currency_ID}`) > -1}
                                                            onChange={selectCurrencieForAdd}
                                                            value={currencie.C_Currency_ID}
                                                    />} 
                                                    label={currencie.ISO_Code} />
                                            </MenuItem>
                                        )): <>Patientez, les devises ne sont pas chargée.</>}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <Typography component='p' onClick={moveToSelectedCurrencies}>
                                        <NavigateNextOutlined 
                                                    style={{color: '#ced4da', fontSize: '54px', cursor: 'pointer'}} 
                                                    />
                                    </Typography>
                                    <Typography component='p' onClick={removeSelectedCurrencies}>
                                        <NavigateBeforeOutlined 
                                                    style={{color: '#ced4da', fontSize: '54px', cursor: 'pointer'}} 
                                                    />
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography component='div' style={listDeviseContainer}>
                                            <TextField
                                                label="Rechercher"
                                                type="search"
                                                variant="filled"
                                                onChange={(event)=> setSearchInAllReadySelectedCurrencie(event.target.value)}
                                            />
                                        {showAlreadySelectedCurrencies && showAlreadySelectedCurrencies.map((currencie, key)=>(
                                            <MenuItem key={key}>
                                            <FormControlLabel 
                                                sx={{
                                                    '& .MuiFormControlLabel-label':{
                                                        fontSize: {xs: '12px', sm: '18px'}                                                 
                                                    }
                                                }}
                                                control={
                                                    <Checkbox 
                                                        checked={selectCurrenciesToRemove.indexOf(`${currencie.C_Currency_ID}`) > -1}
                                                        onChange={selectCurrenciesForRemove}
                                                        value={currencie.C_Currency_ID}
                                                />} 
                                                label={currencie.ISO_Code} />
                                        </MenuItem>
                                        ))}
                                    </Typography>
                                </Grid>
                            </Grid> 
                        </Typography>
                        <Typography component="div" sx={{dipslay: 'flex', mt: 2}}>
                            <Button sx={{mr: 2}} variant="outlined" m={2} onClick={handleCloseAddCurrencieModal}>Quitter</Button>
                            <Button variant="contained" 
                                    disabled={disabledAddDeviseBtn}
                                    onClick={saveSelectedCurrencies}>
                                {disabledAddDeviseBtn && <CircularProgress style={{position: 'absolute'}} />}
                                Valider
                            </Button>
                        </Typography>
                    </Box>
                </Modal>
            </Grid>

            <Grid container sx={{mb: 2}}>
                <Grid item xs={12}>
                    <Card sx={{display: {xs: 'none', sm: 'block'}}}>
                        <CardContent>
                            <TableComponent columns={currenciesColumns}>
                                {(organisationRates?.length) ? organisationRates.map((item, key)=>( 
                                        <TableRow key={key} sx={tRowStyles}>
                                            <TableCell sx={tCellStyle}>{ (key + 1 )}</TableCell>
                                            <TableCell sx={tCellStyle}>{ getCurrencyName(item.C_Currency_ID_To) }</TableCell>
                                            <TableCell contentEditable='true' 
                                                    suppressContentEditableWarning={true} 
                                                    onInput={customizeRateAttributes} 
                                                    name='achat'
                                                    value={item.achat}
                                                    rateid={item.id}
                                                    sx={tCellStyle}>
                                                    { formatCurrency(item.achat, '', 20) }
                                            </TableCell>
                                            <TableCell contentEditable='true' 
                                                    suppressContentEditableWarning={true} 
                                                    onInput={customizeRateAttributes} 
                                                    name='vente'
                                                    value={item.vente}
                                                    rateid={item.id}
                                                    sx={tCellStyle}>
                                                    { formatCurrency(item.vente, '', 20) }
                                            </TableCell>
                                            <TableCell contentEditable='true' 
                                                        suppressContentEditableWarning={true} 
                                                        onInput={customizeRateAttributes} 
                                                        name='marge_minimale'
                                                        value={item.marge_minimale}
                                                        rateid={item.id}
                                                        sx={tCellStyle}>
                                                    { formatCurrency(item.marge_minimale, '', 20)}
                                            </TableCell>
                                            <TableCell contentEditable='true' 
                                                    suppressContentEditableWarning={true} 
                                                    onInput={customizeRateAttributes} 
                                                    name='marge_maximale'
                                                    value={item.marge_maximale }
                                                    rateid={item.id}
                                                    sx={tCellStyle}>
                                                { formatCurrency(item.marge_maximale, '', 20) }
                                            </TableCell>
                                        </TableRow> 
                                    )): <TableRow>
                                            <TableCell colSpan={6} sx={{fontSize: '22px', textAlign: 'center'}}>Aucune dévise n'a été ajouté.</TableCell>
                                        </TableRow>}                           
                            </TableComponent>                       
                        </CardContent>
                    </Card>
                    <Box sx={{display: {xs: 'block', sm: 'none'}}}>
                        {(organisationRates?.length) ? organisationRates.map((item, key)=>( 
                            <MobileTableComponent
                                    key={key} 
                                    item={item}
                                    currencyName={getCurrencyName(item.C_Currency_ID_To)} />
                        )): <p>Aucune dévise n'a été ajouté.</p>} 
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </>
  )
}
export default AdminTemplate(Config);

