import { Box, Button, Card, CardContent, CircularProgress, Grid, TableCell, TableRow, Typography } from '@mui/material';
import React, {useEffect, useState, useContext, useMemo} from 'react'
import { styled  } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import AdminTemplate from './AdminTemplate';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { theme } from '../themes/theme';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import TableComponent from '../components/TableComponent';
import {  historiqueColumns } from '../data/historiqueData';
import AxiosContext from '../context/AxiosContext';
import { useSelector } from 'react-redux';
import formatCurrency from '../functions/formatCurrency';
import { useNavigate } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#fff',
  '& .MuiInputBase-input': {
    padding: '10px 10px',
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%'
  }
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  marginLeft: '22px',
  width: '18%',
  height: '36px',
  bottom: '5px',
  top: '20px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#f5f6f4',
  '&:hover': {
    backgroundColor: '#f5f6f4',
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const tableCardHeadersStyles = {
  color: '#555',
  fontSize: '32px',
  fontFamily : theme.fontFamily.police.main,
  marginBottom: '32px',
}

const card = {
  position: "relative",
  margin:  "0px auto",
  padding:  ".4in .3in",
  color:  "#333",
  backgroundColor:  "#fff",
}

const tRowsStyles = {
  // '&:nth-child(even)':{
  //     bgcolor: '#f1f3f5'
  // },
  
    
}

const achatDevise = {
  color:'#c92a2a',
  textAlign:'center',
  fontFamily: theme.fontFamily.police.main,
}

const venteDevise = {
  color:'#37b24d',
  textAlign:'center',
  fontFamily: theme.fontFamily.police.main,
}

const historiqueHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  // paddingLeft:'20px',
  '@media print' :{
    '@page':
      {
        size: "A4",
        // marginLeft:"-250px",
      },
      'tRowsStyles':{
        display:"none"
      }      
  }
}


const button = {
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  fontFamily: theme.fontFamily.police.main,
}

const buttonPrint = {
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  top:"-15px",
  fontFamily: theme.fontFamily.police.main,
  '@media print' :{
    display: "none"
  }
}

const buttonDanger = {
  backgroundColor:"#C80A0A",
  color:"#fff",
  display: 'flex',
  justifyContent:'center',
  position: 'relative',
  fontFamily: theme.fontFamily.police.main,
}

const btnIcon = {
  display:'flex',
  justifyContent:'space-evenly',
  marginRight:'10px',
}

const actions = {
  display:'flex',
  justifyContent:'space-evenly',
  alignItems:'center',
  fontFamily: theme.fontFamily.police.main,
}



function Historique({setAlert}) {
  const axiosInstance = useContext(AxiosContext);
  const [listTransaction, setListTransaction] = useState([]);
  const [backUpTransaction, setBackUpTransaction] = useState([]);
  const {currentOrganisation, user: {Countries: organisationCountries}} = useSelector((state)=>state.user.user);
  const {user} = useSelector((state)=>state.user);
  const [disabledLoginBtn, setDisabledLoginBtn] = useState(true); 
  const [timer, setTimer] = useState(15 * 60); 
  const navigate = useNavigate(); 
  const [buttonVisible, setButtonVisible] = useState(true);
  const [allDevises, setAllDevises] = useState([]);


  useEffect(()=>{
    (async()=>{
      if(!listTransaction.length){
        await getAllMouvements();
        await getAllDevises();
        setDisabledLoginBtn(false);
      }
    })();
  }, []);


  const getAllDevises = async()=>{        
    const currencies = await axiosInstance.get(`/devises`);
    if(currencies.status === 200){
        setAllDevises(currencies.data);
    }
  }

  const timerdata = (transaction) => {

    const {date}  =  transaction;
    const dateString = (date);

    const time1 = convertirEnTimestamp(dateString);

    console.log(time1);
    
    const time2 = new Date().getTime();
    const difference = Math.abs(time1 - time2);
    const quinzeMinutes = 15 * 60 * 1000;
    if (difference > quinzeMinutes) {
      const interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
    }, 1000);

    return () => clearInterval(interval);
    }else{
      console.log("Hello Word");
    }
        
  }

  const getCurrencyName = (currencyId)=> {       
    const item = allDevises.find((item)=> (parseInt(currencyId) === parseInt(item.C_Currency_ID)));
    return (item) ? item['ISO_Code'] : '';
  }

  const printList = () => {
    window.print();
  }
  
  const getAllMouvements = async()=>{

    const response = await axiosInstance.get(`/transactions/${currentOrganisation}`);
    console.log(response.data);
    if(response.status === 200) {
        setListTransaction(response.data);
        setBackUpTransaction(response.data);
    }
  }

  const PrintOperation  = async (transaction) => {
    navigate('/impression',{ state:{...transaction} });
  } 

  function formatDate(date) {
    var heures = date.getHours();
    var minutes = date.getMinutes();
    var secondes = date.getSeconds();

    // Ajout de zéro au début si nécessaire 
    heures = heures < 10 ? '0' + heures : heures;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    secondes = secondes < 10 ? '0' + secondes : secondes;

    return heures + ':' + minutes + ':' + secondes;
  }

  function formatHeure(date) {
    var heures = date.getHours();
    var minutes = date.getMinutes();
    var secondes = date.getSeconds();

    // Ajout de zéro au début si nécessaire pour maintenir le format à deux chiffres
    heures = heures < 10 ? '0' + heures : heures;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    secondes = secondes < 10 ? '0' + secondes : secondes;

    return heures + ':' + minutes + ':' + secondes;
  }



  const cancelOperation = async (transaction) => {

      const {date, reference,_id}  =  transaction;

      console.log(_id);
      
      const resp =  await axiosInstance.get(`/return-transaction-reference/${reference}`);

      if (resp.status === 200) {

        const checkTransaction = resp.data;

      if(!checkTransaction) {

        var dateString  = (date);
        const item      = new Date(dateString);
        const time1     = item.getTime();
        const time2 = new Date().getTime();
        const difference = Math.abs(time1 - time2);
        const quinzeMinutes = 15 * 60 * 1000;


        if (difference > quinzeMinutes) {
          setDisabledLoginBtn(false);
          setAlert('error', 'Cette transaction ne peut plus être retournée car elle depasse les 15min.');
        } else {
            setDisabledLoginBtn(true);
            
            const res = await axiosInstance.post('/return-transaction', transaction);
            if(res.status === 200){
              await getAllMouvements();
              setDisabledLoginBtn(false);
              setAlert('success', 'La transaction à été retourné avec success.');
              
            }
        }
        }else{
            setDisabledLoginBtn(false);
            setAlert('error', 'Cette transaction à été déjà retourné.');
        }
      }
  }



  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds}`;
  }


function convertirEnTimestamp(dateString) {

  const dateParts = dateString.split(/[\s/,: ]+/);
  const month = dateParts[0] - 1;
  const day   = dateParts[1];
  const year  = dateParts[2];
  const hours = dateParts[4] === "PM" ? parseInt(dateParts[3]) + 12 : parseInt(dateParts[3]);
  const minutes = dateParts[5];
  const seconds = dateParts[6];

  const date = new Date(year, month, day, hours, minutes, seconds);
  const timestamp = date.getTime();
  return timestamp;
}

const isTransactionWithin15Minutes = (transaction) => {
  const transactionTime = new Date(transaction).getTime();
  const currentTime = new Date().getTime();
  const fifteenMinutesInMilliseconds = 15 * 60 * 1000;
  return (currentTime - transactionTime) < fifteenMinutesInMilliseconds;
};

const columns = [
  {
    accessorKey: 'index',
    header: 'N°',
    size: 50,
    Cell: ({ row }) => row.index + 1,
  },
  {
    accessorKey: 'reference',
    header: 'Référence',
    size: 80,
    Cell: ({ row }) => (
      <Box>
        {row.original.returned && <KeyboardReturnIcon style={{ color: "#FF6600" }} />}
        {row.original.vente.reference_no}
      </Box>
    ),
  },
  {
    accessorKey: 'client',
    header: 'Client',
    size: 90,
    Cell: ({ row }) => `${row.original.vente.nom} ${row.original.vente.prenom} (${row.original.vente.telephone})`,
  },
  {
    accessorKey: 'date',
    header: 'Date',
    size: 80,
  },
  {
    accessorKey: 'type',
    header: 'Type',
    size: 80,
    Cell: ({ row }) => (
      <Typography sx={row.original.type === 'vente' ? venteDevise : achatDevise}>
        {row.original.type.toUpperCase()}
      </Typography>
    ),
  },
  {
    accessorKey: 'ISO_Code',
    header: 'Devise',
    size: 80,
  },
  {
    accessorKey: 'exchange_rate',
    header: 'Taux d\'échange',
    size: 80,
    Cell: ({ row }) => formatCurrency(Number.parseFloat(row.original.exchange_rate).toFixed(6)),
  },
  {
    accessorKey: 'quantite',
    header: 'Quantité',
    size: 80,
    Cell: ({ row }) => (
      <Typography sx={row.original.vente.operation === 'vente' ? achatDevise : venteDevise}>
        {`${formatCurrency(parseFloat(row.original.quantite))} ${row.original.ISO_Code}`}
      </Typography>
    ),
  },
  {
    accessorKey: 'montant_total',
    header: 'Montant Total',
    size: 80,
    Cell: ({ cell }) => {
      const transaction = cell.row.original;
      return (
          <Typography sx={transaction.type === 'vente' ? venteDevise : achatDevise}>
              {`${formatCurrency(transaction.vente.montant_total)} ${getCurrencyName(transaction.devise_id_locale)}`}
          </Typography>
      );
  }
  },
  {
    accessorKey: 'actions',
    header: 'Actions',
    size: 120,
    Cell: ({ row }) => (
      <Box sx={actions}>
        <Button sx={button} variant="contained" onClick={() => PrintOperation(row.original)}>
          <LocalPrintshopOutlinedIcon sx={btnIcon} />
        </Button>
        {row.original.returned === false && isTransactionWithin15Minutes(row.original.date) && (
          <Button 
            sx={buttonDanger} 
            variant="contained" 
            onClick={() => cancelOperation(row.original)}
            disabled={!isTransactionWithin15Minutes(row.original.date)}
          >
            <CancelOutlinedIcon sx={btnIcon} />
          </Button>
        )}
      </Box>
    ),
  },
];






if (disabledLoginBtn) {
  return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItem:"center" ,heigth:"100%",padding:"250px 40px",width:"90%", }}>
                <CircularProgress />
      </Box>
    );
}

  return (
    <Box>  
        <Grid container spacing={2}>
            <Grid item xs={6} md={12} lg={12} sm={12}>
                <Card sx={card}>
                    <CardContent>
                      <Typography component='div' spacing={2} sx={historiqueHeader}>
                          <Typography component='p' sx={tableCardHeadersStyles}>
                              Historique des Transactions
                          </Typography> 
                      </Typography>
                        <Typography component='h6'>
                          <Button sx={buttonPrint} variant="contained" onClick ={printList}>
                            <LocalPrintshopOutlinedIcon/>Imprimer
                          </Button> 
                        </Typography>

                        <MaterialReactTable
                        columns={columns}
                        data={listTransaction}
                        enableColumnFilters={false}
                        enableColumnActions={false}
                        enablePagination={true}
                        enableSorting={true}
                        muiTableBodyRowProps={{ sx: tRowsStyles }}
                        muiTableProps={{
                          sx: {
                            tableLayout: 'fixed',
                          },
                        }}
                        renderEmptyRowsFallback={() => (
                          <tr>
                            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                              L'historique de transaction est vide.
                            </td>
                          </tr>
                        )}
                      />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        
    </Box>
  )
}
export default AdminTemplate(Historique);